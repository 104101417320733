<template>
  <section>
    <!-- title text and switch button -->
    <div class="text-center">
      <b-row class="pricing-card">
        <b-col sm="12" md="10" offset-lg="2" lg="6" class="mx-auto">ّ
          <b-alert v-if="data.status == 'success'" variant="success" show>
            <h4 class="alert-heading">{{ $t("Pages.MasterCoin.Title.Successful") }}</h4>
            <div class="alert-body">
              <br />
              <span>{{ data.message }}</span>
              <br /><br />
              <span
                >{{ $t("Pages.MasterCoin.Title.trans_num : {transaction_id}",{transaction_id:data.transactionId}) }}</span
              >
              <br /><br />
            </div>
          </b-alert>
          <b-alert v-if="data.status == 'error'" variant="danger" show>
            <h4 class="alert-heading">{{ $t("Pages.MasterCoin.Title.Unsuccessful") }}</h4>
            <div class="alert-body">
              <br />
              <span>{{ data.message }}</span>
              <br /><br />
            </div>
          </b-alert>

          <b-alert v-if="data.status == 'already_done'" variant="success" show>
            <h4 class="alert-heading">{{ $t("Pages.MasterCoin.Title.already_done") }}</h4>
            <div class="alert-body">
              <br />
              <span>{{ data.message }}</span>
              <br /><br />
            </div>
          </b-alert>

          <b-alert v-if="data.status == 'pending'" variant="warning" show>
            <h4 class="alert-heading">{{ $t("Base.Alert.please_wait") }}</h4>
            <div class="alert-body">
              <br />
              <span>{{ data.message }}</span>
              <br /><br />
            </div>
          </b-alert>

        </b-col>
      </b-row>
    </div>
    <!--/ title text and switch button -->
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BAlert } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
/* eslint-disable global-require */
export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BCard,
    AppCollapseItem,
    AppCollapse,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        status : 'pending'
      },
    };
  },
  created() {
    this.makeToast(
      this.$t("Base.Alert.please_wait"),
      this.$t("Pages.MasterCoin.Alert.recieving_info"),
      "success"
    );
    const payload = {
      Authority: this.$route.query.Authority,
      Status: this.$route.query.Status,
      Free: this.$route.query.Free,
    };
    this.$http
      .post(`/api/coin/packages/pay/callback`, payload)
      .then((response) => {
        if (response.data.status) {
          this.data = {
            status: response.data.status,
            message: this.$t(
              "Pages.MasterCoin.Alert.purchase_success_add_balance"
            ),
            transactionId: response.data.payment_id,
          };
        } else {
          this.data = {
            status: 'error',
            message: response.data.message,
            transactionId: "",
          };
        }
        this.$root.$emit("updateCoin", true);
      })
      .catch(() => {
        this.makeToast(this.$t("Base.Alert.Error"), this.$t("Pages.MasterCoin.Alert.pay_gateway"), "danger");
      });
      this.$root.$emit("updateCoin", true);
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
